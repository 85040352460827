.commonHeading {
  &--h2 {
    margin: 0 0 15px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      margin: 0 0 20px;
      font-size: 30px;
    }
  }

  &--h3 {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      margin: 0 0 20px;
      font-size: 20px;
    }
  }
}
