.commonTextLink {
  position: relative;
  padding-right: 0.25em;
  font-size: 14px;
  font-weight: 400 !important; // stylelint-disable-line declaration-no-important
  line-height: calc(18 / 14);
  color: $common-color-main-red;
  text-decoration: underline;
  word-break: break-word;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: calc(24 / 16);
  }

  &--icon {
    &::after {
      position: relative;
      top: 1px;
      display: inline-block;
      width: 5.84px;
      height: 9.7px;
      margin: 0 0 0 5px;
      content: '';
      background: $common-color-main-red no-repeat center / contain;
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="5.84" height="9.7" viewBox="0 0 5.84 9.7"><path d="M10.293,8.465a1,1,0,0,0,0,1.414L12.414,12l-2.121,2.121a1,1,0,0,0,1.414,1.415l2.829-2.829a1,1,0,0,0,0-1.414L11.707,8.465a1,1,0,0,0-1.414,0Z" transform="translate(-10 -8.172)" fill-rule="evenodd"/></svg>');
    }

    &[target='_blank'] {
      &::after {
        width: 13.3px;
        height: 13.4px;
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13.359" height="13.425" viewBox="0 0 13.359 13.425"><g transform="translate(22971 3426)"><path d="M.805-.7H8.579A1.507,1.507,0,0,1,10.084.805V8.579a1.507,1.507,0,0,1-1.505,1.505H.805A1.507,1.507,0,0,1-.7,8.579V.805A1.507,1.507,0,0,1,.805-.7ZM8.579,8.684a.105.105,0,0,0,.105-.105V.805A.105.105,0,0,0,8.579.7H.805A.105.105,0,0,0,.7.805V8.579a.105.105,0,0,0,.105.105Z" transform="translate(-22970.301 -3425.3)" fill="evenodd"/><path d="M11.855,13.425H4.581a.7.7,0,1,1,0-1.4h7.274a.1.1,0,0,0,.105-.105V4.646a.7.7,0,0,1,1.4,0V11.92A1.507,1.507,0,0,1,11.855,13.425Z" transform="translate(-22971 -3426)" fill="evenodd"/></g></svg>');
      }
    }

    &-pdf[target='_blank'] {
      &::after {
        width: 10.665px;
        height: 13.332px;
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.665" height="13.332" viewBox="0 0 10.665 13.332"><path fill-rule="evenodd" d="M6.39 0a1.333 1.333 0 0 1 .856.311l.087.079 2.942 2.942a1.333 1.333 0 0 1 .385.825l.005.117V12a1.333 1.333 0 0 1-1.233 1.33h-8.1A1.333 1.333 0 0 1 0 12.1V1.333A1.333 1.333 0 0 1 1.233 0h.1ZM5.333 1.333h-4V12h8V5.333h-3a1 1 0 0 1-1-.9v-.1Zm.659 5.231a4.148 4.148 0 0 0 1.49 2.581.667.667 0 0 1-.659 1.141 4.148 4.148 0 0 0-2.98 0 .667.667 0 0 1-.659-1.141 4.147 4.147 0 0 0 1.49-2.581.667.667 0 0 1 1.317.001ZM5.331 8.1l-.459.8h.92l-.461-.8Zm1.335-6.491V4h2.39Z"/></svg>');
      }
    }
  }
}
