.commonText {
  font-size: 14px !important; // stylelint-disable-line declaration-no-important
  font-weight: 400 !important; // stylelint-disable-line declaration-no-important
  line-height: 1.5 !important; // stylelint-disable-line declaration-no-important

  @include media-breakpoint-up(md) {
    font-size: 16px !important; // stylelint-disable-line declaration-no-important
  }

  &--font-weight-bold {
    font-weight: $bold !important; // stylelint-disable-line declaration-no-important
  }

  &.lh-s {
    line-height: 1.5 !important; // stylelint-disable-line declaration-no-important
  }

  &.lh-m {
    line-height: 1.75 !important; // stylelint-disable-line declaration-no-important
  }

  &.lh-l {
    line-height: 2 !important; // stylelint-disable-line declaration-no-important
  }
}
