.commonPageArea {
  padding: 52px 0 100px;

  @include media-breakpoint-up(md) {
    padding: 0 0 140px;
  }

  .defaultSizeImg {
    display: block;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    img {
      @include media-breakpoint-up(md) {
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__inner {
    @include inner();
  }

  &__body {
    margin: 20px auto 0;

    @include inner();

    @include media-breakpoint-up(md) {
      margin: 40px auto 0;
    }

    &--narrow {
      @include media-breakpoint-up(md) {
        @include narrow-inner();
      }
    }

    &--noGap {
      padding: 0;
    }
  }

  &__tab {
    display: flex;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 50px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      border-bottom: 3px solid #ccc;

      &.is-active {
        font-weight: 700;
        color: $common-color-main-red;
        pointer-events: none;
        border-color: $common-color-main-red;
      }
    }

    + .commonPageArea__inner {
      margin: 25px auto 0;
    }
  }

  &--theme-gray {
    background: #f2f2f2;
  }

  &--theme-white {
    background: #fff;
  }

  &--layout-app {
    padding: 0 0 50px;
  }

  &--noGap {
    padding: 52px 0 0;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  &--sm-noGap {
    padding: 52px 0 0;

    @include media-breakpoint-up(md) {
      padding: 0 0 140px;
    }
  }
}
