.commonAnchorLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;
}
@media (min-width: 768px) {
  .commonAnchorLink {
    justify-content: center;
    gap: 0;
  }
}
.commonAnchorLink__item {
  width: 50%;
  height: 58px;
  padding: 0;
  text-align: center;
  border-right: 1px solid #ddd;
}
@media (min-width: 768px) {
  .commonAnchorLink__item {
    width: auto;
    height: auto;
    padding: 0 30px;
    text-align: left;
  }
  .commonAnchorLink__item:first-child {
    border-left: 1px solid #ddd;
  }
}
@media (max-width: 767.98px) {
  .commonAnchorLink__item:nth-child(2n-1) {
    border-left: 1px solid #ddd;
  }
}
.commonAnchorLink__anchor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .commonAnchorLink__anchor {
    flex-direction: row;
    align-items: center;
  }
}
.commonAnchorLink__anchor svg {
  display: block;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .commonAnchorLink__anchor svg {
    margin: 0 0 0 5px;
  }
}
.commonAnchorLink__text {
  display: grid;
  height: 60%;
  font-size: 13px;
  line-height: 1.45;
  letter-spacing: 0;
  place-items: center;
}
@media (min-width: 768px) {
  .commonAnchorLink__text {
    height: auto;
    font-size: 16px;
  }
}

.commonBredcrumbList {
  margin: 10px 0;
}
@media (min-width: 768px) {
  .commonBredcrumbList {
    margin: 10px 0 30px;
  }
}
.commonBredcrumbList__item {
  position: relative;
  display: inline;
  padding: 0 12px 0 0;
  margin: 0 8px 0 0;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}
.commonBredcrumbList__item::before {
  position: absolute;
  top: 7px;
  right: 0;
  display: block;
  width: 3.7px;
  height: 6.4px;
  content: "";
  background: #333 no-repeat center/contain;
  mask-image: url("/assets/images/pages/common/common_bredcrumb-arw.svg");
}
.commonBredcrumbList__item a {
  display: inline;
  text-decoration: underline;
  text-underline-offset: 5px;
}
@media (hover: hover) and (pointer: fine) {
  .commonBredcrumbList__item a:hover {
    text-decoration: none;
  }
}
.commonBredcrumbList__item:last-child {
  color: #707070;
}
.commonBredcrumbList__item:last-child::before {
  display: none;
}
.commonBredcrumbList--theme-white {
  color: #fff;
}
.commonBredcrumbList--theme-white .commonBredcrumbList__item::before {
  background: #fff no-repeat center/contain;
}

.commonCautionText {
  display: inline-block;
  padding-left: 1em;
  font-size: 12px;
  line-height: calc(18 / 12);
  color: #555;
  text-align: left !important;
  text-indent: -1em;
}
.commonCautionText--noIndent {
  padding-left: 0;
  text-indent: 0;
}

.commonContBlock:nth-of-type(n + 2) {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .commonContBlock:nth-of-type(n + 2) {
    margin-top: 80px;
  }
}

.commonHeading--h2 {
  margin: 0 0 15px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .commonHeading--h2 {
    margin: 0 0 20px;
    font-size: 30px;
  }
}
.commonHeading--h3 {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .commonHeading--h3 {
    margin: 0 0 20px;
    font-size: 20px;
  }
}

.commonNews {
  position: relative;
  display: block;
  padding: 20px 30px 20px 15px;
  text-decoration: none !important;
}
@media (min-width: 768px) {
  .commonNews {
    display: flex;
    padding: 20px 30px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .commonNews:hover {
    color: #b81c22;
  }
}
.commonNews::before {
  position: absolute;
  top: calc(50% - 3.5px);
  right: 14.5px;
  width: 4.829px;
  height: 7.656px;
  content: "";
  background: #b81c22 no-repeat center/contain;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="4.829" height="7.656" viewBox="0 0 4.829 7.656"><path d="M10.293,8.465a1,1,0,0,0,0,1.414L12.414,12l-2.121,2.121a1,1,0,0,0,1.414,1.415l2.829-2.829a1,1,0,0,0,0-1.414L11.707,8.465a1,1,0,0,0-1.414,0Z" transform="translate(-10 -8.172)" fill-rule="evenodd"/></svg>');
}
@media (min-width: 768px) {
  .commonNews::before {
    right: 29.5px;
  }
}
.commonNews__head {
  display: flex;
  align-items: baseline;
}
@media (min-width: 768px) {
  .commonNews__head {
    width: 178px;
  }
}
.commonNews__head .commonNewsDate {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
}
.commonNews__head .commonNewsLabel {
  margin: 0 0 0 10px;
}
.commonNews__text {
  margin: 5px 0 0;
  font-size: 14px;
  font-weight: 700;
  line-height: calc(21 / 14);
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .commonNews__text {
    flex: 1;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
}
.commonNews + .commonNews {
  border-top: 1px dashed #ccc;
}

.commonPageArea {
  padding: 52px 0 100px;
}
@media (min-width: 768px) {
  .commonPageArea {
    padding: 0 0 140px;
  }
}
.commonPageArea .defaultSizeImg {
  display: block;
}
@media (min-width: 768px) {
  .commonPageArea .defaultSizeImg {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .commonPageArea .defaultSizeImg img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}
.commonPageArea__inner {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .commonPageArea__inner {
    width: 1200px;
    padding-right: 0;
    padding-left: 0;
  }
}
.commonPageArea__body {
  margin: 20px auto 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .commonPageArea__body {
    width: 1200px;
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .commonPageArea__body {
    margin: 40px auto 0;
  }
}
@media (min-width: 768px) {
  .commonPageArea__body--narrow {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .commonPageArea__body--narrow {
    width: 790px;
    padding-right: 0;
    padding-left: 0;
  }
}
.commonPageArea__body--noGap {
  padding: 0;
}
.commonPageArea__tab {
  display: flex;
}
.commonPageArea__tab .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  border-bottom: 3px solid #ccc;
}
.commonPageArea__tab .tab.is-active {
  font-weight: 700;
  color: #b81c22;
  pointer-events: none;
  border-color: #b81c22;
}
.commonPageArea__tab + .commonPageArea__inner {
  margin: 25px auto 0;
}
.commonPageArea--theme-gray {
  background: #f2f2f2;
}
.commonPageArea--theme-white {
  background: #fff;
}
.commonPageArea--layout-app {
  padding: 0 0 50px;
}
.commonPageArea--noGap {
  padding: 52px 0 0;
}
@media (min-width: 768px) {
  .commonPageArea--noGap {
    padding: 0;
  }
}
.commonPageArea--sm-noGap {
  padding: 52px 0 0;
}
@media (min-width: 768px) {
  .commonPageArea--sm-noGap {
    padding: 0 0 140px;
  }
}

.commonPageHeading {
  margin-top: 10px;
  font-size: 26px;
  font-weight: 700;
  line-height: calc(39 / 26);
}
@media (min-width: 768px) {
  .commonPageHeading {
    font-size: 36px;
  }
}
.commonPageHeading__caution {
  display: block;
  margin: 5px 0 0;
  font-size: 12px;
  font-weight: 400;
  color: #707070;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .commonPageHeading__caution {
    margin: 10px 0 0;
  }
}
.commonPageHeading + * {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .commonPageHeading + * {
    margin-top: 40px;
  }
}
.commonPageHeading--noGap {
  margin-top: 0;
}

.commonTab {
  display: flex;
  border-bottom: none !important;
}
.commonTab__btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #333 !important;
  letter-spacing: 0;
  background: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 3px solid #ccc;
  border-left: none !important;
}
.commonTab__btn.is-active {
  font-weight: 700;
  color: #b81c22;
  pointer-events: none;
  border-color: #b81c22;
}

.commonTable--layout2 th {
  font-size: 14px;
  font-weight: 700;
  line-height: calc(19 / 14);
  color: #333;
  text-align: center !important;
  letter-spacing: 0;
  background: #eaeaea;
  border-color: #ddd !important;
  border-bottom: none !important;
}
@media (min-width: 768px) {
  .commonTable--layout2 th {
    font-size: 16px;
  }
}
.commonTable--layout2 th:first-child {
  border-left: none;
}
.commonTable--layout2 th:last-child {
  border-right: none;
}
.commonTable--layout2 td {
  font-size: 12px;
  font-weight: 400;
  line-height: calc(21 / 14);
  text-align: center;
  vertical-align: middle !important;
  border-color: #ddd !important;
}
@media (min-width: 768px) {
  .commonTable--layout2 td {
    font-size: 16px;
  }
}
.commonTable--layout2 td:first-child {
  border-left: none;
}
.commonTable--layout2 td:last-child {
  border-right: none;
}
.commonTable--layout3 {
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.commonTable--layout3 .row {
  display: flex !important;
  padding: 0 !important;
  border-bottom: none !important;
}
.commonTable--layout3 .row + .row {
  position: relative;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.commonTable--layout3 .head {
  width: 84px !important;
  padding: 15px 14px !important;
  font-size: 14px;
  font-weight: 700;
  line-height: calc(24 / 16);
  letter-spacing: 0;
  background: #eaeaea;
}
@media (min-width: 768px) {
  .commonTable--layout3 .head {
    width: 159px !important;
    font-size: 16px;
  }
}
.commonTable--layout3 .inner {
  width: calc(100% - 84px) !important;
  padding: 17px 14.5px !important;
  font-size: 12px;
  font-weight: 400;
  line-height: calc(24 / 16);
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .commonTable--layout3 .inner {
    font-size: 16px;
    width: calc(100% - 159px) !important;
  }
}

.commonText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}
@media (min-width: 768px) {
  .commonText {
    font-size: 16px !important;
  }
}
.commonText--font-weight-bold {
  font-weight: 700 !important;
}
.commonText.lh-s {
  line-height: 1.5 !important;
}
.commonText.lh-m {
  line-height: 1.75 !important;
}
.commonText.lh-l {
  line-height: 2 !important;
}

.commonTextLink {
  position: relative;
  padding-right: 0.25em;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: calc(18 / 14);
  color: #b81c22;
  text-decoration: underline;
  word-break: break-word;
}
@media (min-width: 768px) {
  .commonTextLink {
    font-size: 16px;
    line-height: calc(24 / 16);
  }
}
.commonTextLink--icon::after {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 5.84px;
  height: 9.7px;
  margin: 0 0 0 5px;
  content: "";
  background: #b81c22 no-repeat center/contain;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="5.84" height="9.7" viewBox="0 0 5.84 9.7"><path d="M10.293,8.465a1,1,0,0,0,0,1.414L12.414,12l-2.121,2.121a1,1,0,0,0,1.414,1.415l2.829-2.829a1,1,0,0,0,0-1.414L11.707,8.465a1,1,0,0,0-1.414,0Z" transform="translate(-10 -8.172)" fill-rule="evenodd"/></svg>');
}
.commonTextLink--icon[target=_blank]::after {
  width: 13.3px;
  height: 13.4px;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="13.359" height="13.425" viewBox="0 0 13.359 13.425"><g transform="translate(22971 3426)"><path d="M.805-.7H8.579A1.507,1.507,0,0,1,10.084.805V8.579a1.507,1.507,0,0,1-1.505,1.505H.805A1.507,1.507,0,0,1-.7,8.579V.805A1.507,1.507,0,0,1,.805-.7ZM8.579,8.684a.105.105,0,0,0,.105-.105V.805A.105.105,0,0,0,8.579.7H.805A.105.105,0,0,0,.7.805V8.579a.105.105,0,0,0,.105.105Z" transform="translate(-22970.301 -3425.3)" fill="evenodd"/><path d="M11.855,13.425H4.581a.7.7,0,1,1,0-1.4h7.274a.1.1,0,0,0,.105-.105V4.646a.7.7,0,0,1,1.4,0V11.92A1.507,1.507,0,0,1,11.855,13.425Z" transform="translate(-22971 -3426)" fill="evenodd"/></g></svg>');
}
.commonTextLink--icon-pdf[target=_blank]::after {
  width: 10.665px;
  height: 13.332px;
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10.665" height="13.332" viewBox="0 0 10.665 13.332"><path fill-rule="evenodd" d="M6.39 0a1.333 1.333 0 0 1 .856.311l.087.079 2.942 2.942a1.333 1.333 0 0 1 .385.825l.005.117V12a1.333 1.333 0 0 1-1.233 1.33h-8.1A1.333 1.333 0 0 1 0 12.1V1.333A1.333 1.333 0 0 1 1.233 0h.1ZM5.333 1.333h-4V12h8V5.333h-3a1 1 0 0 1-1-.9v-.1Zm.659 5.231a4.148 4.148 0 0 0 1.49 2.581.667.667 0 0 1-.659 1.141 4.148 4.148 0 0 0-2.98 0 .667.667 0 0 1-.659-1.141 4.147 4.147 0 0 0 1.49-2.581.667.667 0 0 1 1.317.001ZM5.331 8.1l-.459.8h.92l-.461-.8Zm1.335-6.491V4h2.39Z"/></svg>');
}