.commonTab {
  display: flex;
  border-bottom: none !important; // stylelint-disable-line declaration-no-important

  &__btn {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    color: $common-color-main-black !important; // stylelint-disable-line declaration-no-important
    letter-spacing: 0;
    background: none !important; // stylelint-disable-line declaration-no-important
    border-top: none !important; // stylelint-disable-line declaration-no-important
    border-right: none !important; // stylelint-disable-line declaration-no-important
    border-bottom: 3px solid #ccc;
    border-left: none !important; // stylelint-disable-line declaration-no-important

    &.is-active {
      font-weight: 700;
      color: $common-color-main-red;
      pointer-events: none;
      border-color: $common-color-main-red;
    }
  }
}
