.commonNews {
  position: relative;
  display: block;
  padding: 20px 30px 20px 15px;
  text-decoration: none !important; // stylelint-disable-line declaration-no-important

  @include media-breakpoint-up(md) {
    display: flex;
    padding: 20px 30px;
  }

  @include hover() {
    color: $common-color-main-red;
  }

  &::before {
    position: absolute;
    top: calc(50% - 3.5px);
    right: 14.5px;
    width: 4.829px;
    height: 7.656px;
    content: '';
    background: $common-color-main-red no-repeat center / contain;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="4.829" height="7.656" viewBox="0 0 4.829 7.656"><path d="M10.293,8.465a1,1,0,0,0,0,1.414L12.414,12l-2.121,2.121a1,1,0,0,0,1.414,1.415l2.829-2.829a1,1,0,0,0,0-1.414L11.707,8.465a1,1,0,0,0-1.414,0Z" transform="translate(-10 -8.172)" fill-rule="evenodd"/></svg>');

    @include media-breakpoint-up(md) {
      right: 29.5px;
    }
  }

  &__head {
    display: flex;
    align-items: baseline;

    @include media-breakpoint-up(md) {
      width: 178px;
    }

    .commonNewsDate {
      font-family: $font-roboto;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
    }

    .commonNewsLabel {
      margin: 0 0 0 10px;
    }
  }

  &__text {
    margin: 5px 0 0;
    font-size: 14px;
    font-weight: 700;
    line-height: calc(21 / 14);
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      flex: 1;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }

  + .commonNews {
    border-top: 1px dashed #ccc;
  }
}
