.commonAnchorLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px 0;

  @include media-breakpoint-up(md) {
    justify-content: center;
    gap: 0;
  }

  &__item {
    width: 50%;
    height: 58px;
    padding: 0;
    text-align: center;
    border-right: 1px solid #ddd;

    @include media-breakpoint-up(md) {
      width: auto;
      height: auto;
      padding: 0 30px;
      text-align: left;

      &:first-child {
        border-left: 1px solid #ddd;
      }
    }

    @include media-breakpoint-down(sm) {
      &:nth-child(2n-1) {
        border-left: 1px solid #ddd;
      }
    }
  }

  &__anchor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    svg {
      display: block;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 5px;
      }
    }
  }

  &__text {
    display: grid;
    height: 60%;
    font-size: 13px;
    line-height: 1.45;
    letter-spacing: 0;
    place-items: center;

    @include media-breakpoint-up(md) {
      height: auto;
      font-size: 16px;
    }
  }
}
