.commonCautionText {
  display: inline-block;
  padding-left: 1em;
  font-size: 12px;
  line-height: calc(18 / 12);
  color: #555;
  text-align: left !important; // stylelint-disable-line declaration-no-important
  text-indent: -1em;

  &--noIndent {
    padding-left: 0;
    text-indent: 0;
  }
}
