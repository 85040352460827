.commonTable {
  &--layout2 {
    th {
      font-size: 14px;
      font-weight: 700;
      line-height: calc(19 / 14);
      color: $common-color-main-black;
      text-align: center !important; // stylelint-disable-line declaration-no-important
      letter-spacing: 0;
      background: #eaeaea;
      border-color: #ddd !important; // stylelint-disable-line declaration-no-important
      border-bottom: none !important; // stylelint-disable-line declaration-no-important

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      &:first-child {
        border-left: none; // stylelint-disable-line declaration-no-important
      }

      &:last-child {
        border-right: none; // stylelint-disable-line declaration-no-important
      }
    }

    td {
      font-size: 12px;
      font-weight: 400;
      line-height: calc(21 / 14);
      text-align: center;
      vertical-align: middle !important; // stylelint-disable-line declaration-no-important
      border-color: #ddd !important; // stylelint-disable-line declaration-no-important

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      &:first-child {
        border-left: none; // stylelint-disable-line declaration-no-important
      }

      &:last-child {
        border-right: none; // stylelint-disable-line declaration-no-important
      }
    }
  }

  &--layout3 {
    position: relative;
    display: flex !important; // stylelint-disable-line declaration-no-important
    flex-direction: column !important; // stylelint-disable-line declaration-no-important
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .row {
      display: flex !important; // stylelint-disable-line declaration-no-important
      padding: 0 !important; // stylelint-disable-line declaration-no-important
      border-bottom: none !important; // stylelint-disable-line declaration-no-important

      + .row {
        position: relative;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
    }

    .head {
      width: 84px !important; // stylelint-disable-line declaration-no-important
      padding: 15px 14px !important; // stylelint-disable-line declaration-no-important
      font-size: 14px;
      font-weight: 700;
      line-height: calc(24 / 16);
      letter-spacing: 0;
      background: #eaeaea;

      @include media-breakpoint-up(md) {
        width: 159px !important; // stylelint-disable-line declaration-no-important
        font-size: 16px;
      }
    }

    .inner {
      width: calc(100% - 84px) !important; // stylelint-disable-line
      padding: 17px 14.5px !important; // stylelint-disable-line declaration-no-important
      font-size: 12px;
      font-weight: 400;
      line-height: calc(24 / 16);
      letter-spacing: 0;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        width: calc(100% - 159px) !important; // stylelint-disable-line
      }
    }
  }
}
