@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name) {
      @content;
    }
  }
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  clip-path: inset(50%);
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin inner {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(md) {
    width: 1200px;
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin narrow-inner {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(md) {
    width: 790px;
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin col-md-2 {
  @include media-breakpoint-up(md) {
    > * {
      width: calc(576 / 1200 * 100%);
    }
  }
}

@mixin col-md-3 {
  @include media-breakpoint-up(md) {
    > * {
      width: calc(368 / 1200 * 100%);
    }
  }
}

@mixin col-md-4 {
  @include media-breakpoint-up(md) {
    > * {
      width: calc(264 / 1200 * 100%);
    }
  }
}

@mixin col-md-6 {
  @include media-breakpoint-up(md) {
    > * {
      width: calc(160 / 1200 * 100%);
    }
  }
}

@mixin col-sp-1 {
  @include media-breakpoint-down(sm) {
    > * {
      width: 100%;
    }
  }
}

@mixin col-sp-2 {
  @include media-breakpoint-down(sm) {
    > * {
      width: calc(165 / 375 * 100vw);
    }
  }
}

@mixin col-sp-3 {
  @include media-breakpoint-down(sm) {
    > * {
      width: calc(105 / 375 * 100vw);
    }
  }
}

@mixin col-sp-4 {
  @include media-breakpoint-down(sm) {
    > * {
      width: calc(75 / 375 * 100vw);
    }
  }
}

@mixin col-sp-6 {
  @include media-breakpoint-down(sm) {
    > * {
      width: calc(45 / 375 * 100vw);
    }
  }
}
