.commonBredcrumbList {
  margin: 10px 0;

  @include media-breakpoint-up(md) {
    margin: 10px 0 30px;
  }

  &__item {
    position: relative;
    display: inline;
    padding: 0 12px 0 0;
    margin: 0 8px 0 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;

    &::before {
      position: absolute;
      top: 7px;
      right: 0;
      display: block;
      width: 3.7px;
      height: 6.4px;
      content: '';
      background: $common-color-main-black no-repeat center / contain;
      mask-image: url('/assets/images/pages/common/common_bredcrumb-arw.svg');
    }

    a {
      display: inline;
      text-decoration: underline;
      text-underline-offset: 5px;

      @include hover() {
        text-decoration: none;
      }
    }

    &:last-child {
      color: #707070;

      &::before {
        display: none;
      }
    }
  }

  &--theme-white {
    color: #fff;

    .commonBredcrumbList {
      &__item {
        &::before {
          background: #fff no-repeat center / contain;
        }
      }
    }
  }
}
