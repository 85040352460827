.commonPageHeading {
  margin-top: 10px;
  font-size: 26px;
  font-weight: 700;
  line-height: calc(39 / 26);

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }

  &__caution {
    display: block;
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: 400;
    color: #707070;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      margin: 10px 0 0;
    }
  }

  + * {
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
  }

  &--noGap {
    margin-top: 0;
  }
}
